import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { zip } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import * as base32 from 'hi-base32';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'StellaReportTable';
  columns = ['sn', 'mac', 'description', 'version', 'timestamp', 'calibrationPass', 'report', 'trend'];
  dataSource = new MatTableDataSource<ExtendedReport>();
  dateFormat = 'd-MM-yy, H:mm';
  @ViewChild(MatSort) sort: MatSort;

  constructor(private http: HttpClient) { }

  ngAfterViewInit(): void {
    zip(
      this.fetchReports(),
      this.fetchNames()
    ).pipe(
      map(([res, names]) => res.map(r => {
        const customName = names.find(n => n.mac.localeCompare(r.stella.mac) === 0);
        return {
          ...r,
          ...r.stella,
          description: customName ? customName.name : '',
          sn: calculateSn(r.stella.mac)
        }
      })),
      tap(res => {
        this.dataSource.data = res;
        this.dataSource.sort = this.sort;
        this.sort.sort({ id: 'timestamp', start: 'desc', disableClear: false });
      }),
      tap(() => this.turnOnSyncing())
    ).subscribe();
  }

  turnOnSyncing() {
    setInterval(() => {
      zip(
        this.fetchReports(),
        this.fetchNames()
      ).pipe(
        map(([res, names]) => res.map(r => {
          const customName = names.find(n => n.mac.localeCompare(r.stella.mac) === 0);
          return {
            ...r,
            ...r.stella,
            description: customName ? customName.name : '',
            sn: calculateSn(r.stella.mac)
          }
        })),
        tap(res => {
          this.dataSource.data = res;
        })
      ).subscribe();
    }, 60*1e3)
  }

  fetchReports() {
    return this.http.get<Report[]>(`https://functions.egzotech.com/fetch_reports`);
  }

  fetchNames() {
    return this.http.get<Name[]>('https://functions.egzotech.com/fetch_stella_bio_names');
  }

  focusOut({description, mac}) {
    this.http.post<any>('https://functions.egzotech.com/instert_update_stella_bio_name', {mac, name: description}).subscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  print(element: ExtendedReport) {
    console.log('DO NOTHING - WAITING FOR ADAM', element)
  }

  openReport(url: string) {
    window.open(url);
  }

  sortData(_) {
  }

  getSuccessStellas(): number {
    return this.dataSource.data.filter(f => f.calibrationPass).length;
  }
  getFailedStellas(): number {
    return this.dataSource.data.filter(f => !f.calibrationPass).length;
  }
}

interface StellaDetails {
  mac: string;
  version: string;
  calibrationPass: boolean;
  timestamp: number;
}

interface Report {
  id: string;
  name: string;
  url: string;
  stella: StellaDetails;
}

interface ExtendedReport extends Report{
  description: string;
  sn: string;
  mac: string;
  version: string;
  calibrationPass: boolean;
  timestamp: number;
}

interface Name {
  id: number;
  mac: string;
  name: string;
}

function calculateSn(mac: string): string {
  const macProduct = mac.split(':').filter((_, i) => i !== 1).join('');
  return base32.encode(hexToString(macProduct));
}


function hexToString(hex) {
  if (!hex.match(/^[0-9a-fA-F]+$/)) {
    throw new Error('is not a hex string.');
  }
  if (hex.length % 2 !== 0) {
    hex = '0' + hex;
  }
  var bytes = [];
  for (var n = 0; n < hex.length; n += 2) {
    var code = parseInt(hex.substr(n, 2), 16)
    bytes.push(code);
  }
  return bytes;
}