<mat-toolbar color="primary" class="toolbar"></mat-toolbar>
<div class="app">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Aa" #input />
  </mat-form-field>
  <p class="sum-text">
    Stella BIO:
    <span class="success">{{ getSuccessStellas() }} correct</span> /
    <span class="failure">{{ getFailedStellas() }} failed </span> /
    {{ dataSource.data.length }} all
  </p>
  <table
    class="reports-table mat-elevation-z8"
    mat-table
    matSort
    [dataSource]="dataSource"
    (matSortChange)="sortData($event)"
  >
    <ng-container matColumnDef="mac">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>MAC</th>
      <td mat-cell *matCellDef="let element">
        {{ element.mac }}
      </td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        Version
      </th>
      <td mat-cell *matCellDef="let element" class="center">
        {{ element.version }}
      </td>
    </ng-container>
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        Last report date
      </th>
      <td mat-cell *matCellDef="let element" class="center">
        {{ element.timestamp | date: dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="calibrationPass">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center">
        Pass
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="pass center"
        [ngClass]="{ success: element.calibrationPass }"
      >
        {{ element.calibrationPass ? "PASS" : "FAILED" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="report">
      <th mat-header-cell *matHeaderCellDef class="center">Open report</th>
      <td mat-cell *matCellDef="let element" class="center">
        <button mat-icon-button (click)="openReport(element.url)">
          <mat-icon>description</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="trend">
      <th mat-header-cell *matHeaderCellDef>Trend</th>
      <td mat-cell *matCellDef="let element">
        <div class="trend-row">
          <div
            class="trend-circle"
            [ngClass]="tr.result ? 'pass' : 'fail'"
            *ngFor="let tr of element.trend"
            [matTooltip]="tr.timestamp | date: dateFormat"
            (click)="openReport(tr.url)"
          ></div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button>Show all reports</button>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Custom name</th>
      <td mat-cell *matCellDef="let element">
        <input
          class="stella-name"
          [(ngModel)]="element.description"
          (focusout)="focusOut(element)"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="sn">
      <th mat-header-cell *matHeaderCellDef>Serial Number</th>
      <td mat-cell *matCellDef="let element">
        {{ element.sn }}
        <button mat-icon-button (click)="print(element)"><mat-icon class="print-icon">print</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      class="row"
      [ngClass]="{ failed: !row.stella.calibrationPass }"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No data matching the filter "{{ input.value }}"
      </td>
    </tr>
  </table>
</div>
